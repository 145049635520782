<template>
  <div>
    <Loading :loadMask="isLoad" />
    <section class="page-wrap page-mountain">
      <transition name="fade">
        <div class="video-lightbox" id="videoLightbox" v-if="videoLightboxShow">
          <div class="close-video" id="closeVideo" @click="closeVideo">
            <img src="@/assets/img/close.svg" alt="" />
          </div>
          <div class="video-area">
            <h2>{{ videoLightboxTit }}</h2>
            <p>{{ videoLightboxTxt }}</p>
            <iframe
              width="560"
              height="315"
              :src="videoLightboxLink"
              frameborder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="allowfullscreen"
            ></iframe>
          </div>
        </div>
      </transition>
      <Logo />
      <NavArea />
      <div class="cricle">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="video-list">
        <h2>
          搜尋：{{ srcTxt }}
          <!-- /InfoList/Language -->
          <!-- <router-link
            :to="{
              name: 'InfoList',
              params: { id: typeName },
            }"
            class="back"
          >
            返回
          </router-link> -->
          <!-- <a class="back" @click="backPage">返回</a> -->
        </h2>
        <div style="text-align: center; padding: 10px 0" v-if="isNodata">
          無資料。
        </div>
        <ul v-else>
          <VideoItem
            v-for="(item, index) in list"
            :key="index"
            :id="item.video_id"
            :ytUrl="item.youtube_url"
            :ipcfUrl="item.ipcf_url"
            :imageUrl="item.image_url"
            :tit="item.title"
            :txt="item.content"
          />
        </ul>
      </div>
      <PageFooter />
    </section>
  </div>
</template>
<script>
import NavArea from "@/components/NavArea.vue";
import Loading from "@/components/Loading.vue";
import Logo from "@/components/Logo.vue";
import VideoItem from "@/components/VideoItem.vue";
import PageFooter from "@/components/PageFooter.vue";

import { apiSearch } from "@/api/api.js";

export default {
  data() {
    return {
      isLoad: true,
      isNodata: false,
      typeName: "Mountain", // /InfoList/Mountain(typeName)
      videoAreaTit: "山海誌", //主標題
      videoAreaName: "回家系列", //影片分類區塊名稱
      //=== 區域ID ===
      srcTxt: "",
      //=== 影片燈箱 ===
      videoLightboxShow: false,
      videoLightboxLink: "https://www.youtube.com/embed/mGv0ze0lHKA",
      videoLightboxTit: "愛上部落，從此在這裡紮根 1",
      videoLightboxTxt:
        "在這裡，你會看到原本在外的遊子，回到部落，創造自己的故事。在這裡，你會看到來自他國的人們，愛上部落，從此在這裡紮根。在這裡，你會看到這群山海的子民，為了部落，靠雙手努力前進。為了傳遞我們的勇氣，《人物誌》用影像記錄著我們堅毅的身影。",
      //=== 影片列表 ===
      list: [
        // {
        //   video_id: 1,
        //   youtube_url: "mNQwhIx_0CM",
        //   ipcf_url: "https://www.youtube.com/embed/5aPE8Fbxb5g",
        //   image_url:
        //     '"https://www.kanahei.com/kanaheiwp/wp-content/uploads/2021/03/Ew0xG57VkAMpyv-.jpeg"',
        //   // videoImg: "https://img.youtube.com/vi/mNQwhIx_0CM/maxresdefault.jpg",
        //   // videoLink: "https://www.youtube.com/embed/mGv0ze0lHKA",
        //   title: "愛上部落，從此在這裡紮根 1", //title
        //   //content
        //   content:
        //     "1.在這裡，你會看到原本在外的遊子，回到部落，創造自己的故事。在這裡，你會看到來自他國的人們，愛上部落，從此在這裡紮根。在這裡，你會看到這群山海的子民，為了部落，靠雙手努力前進。為了傳遞我們的勇氣，《人物誌》用影像記錄著我們堅毅的身影。",
        // },
        // {
        //   id: 2,
        //   videoImg: "https://img.youtube.com/vi/t0xHlgWQvAk/maxresdefault.jpg",
        //   videoLink: "https://www.youtube.com/embed/aYxYbxrD9mE",
        //   tit: "愛上部落，從此在這裡紮根 2",
        //   txt:
        //     "2.在這裡，你會看到原本在外的遊子，回到部落，創造自己的故事。在這裡，你會看到來自他國的人們，愛上部落，從此在這裡紮根。在這裡，你會看到這群山海的子民，為了部落，靠雙手努力前進。為了傳遞我們的勇氣，《人物誌》用影像記錄著我們堅毅的身影。",
        // },
      ],
    };
  },
  mounted() {
    window.navClick();
    //=== 取得分類 ===
    // var typeUrl = this.$route.params.id;
    var nowSrcTxt = this.$route.query.src;
    this.typeLoad(nowSrcTxt);
    //=== 關閉Load ===
    this.isLoad = false;
    //=== 接收影片開啟 ===
    this.$bus.$on("sendVideo", (data) => {
      var openId = this.list.findIndex((item) => {
        return item.video_id == data; // 0
      });
      var { youtube_url, ipcf_url, title, content } = this.list[openId];
      this.videoLightboxShow = true;
      var videoLink;
      if (youtube_url) {
        videoLink = "https://www.youtube.com/embed/" + youtube_url;
      } else {
        videoLink = ipcf_url;
      }
      this.videoLightboxLink = videoLink;
      this.videoLightboxTit = title;
      this.videoLightboxTxt = content;
      // this.list.splice(delId, 1);
      // this.list.splice(this.list.indexOf(item), 1);
    });
  },
  methods: {
    //=== 讀取頁面類別資料 ===
    typeLoad(typeUrl) {
      //== 連結分類位置 ==
      //this.typeName = typeUrl;
      //== 區域ID ==
      // var areaIdUrl = this.$route.query.area;
      if (typeUrl == "") {
        this.backPage();
        return;
      }
      this.srcTxt = typeUrl;
      //== 分類標題 ==
      // switch (typeUrl) {
      //   case "Mountain":
      //     this.videoAreaTit = "山海誌";
      //     break;
      //   case "People":
      //     this.videoAreaTit = "人物誌";
      //     break;
      //   case "Food":
      //     this.videoAreaTit = "美食誌";
      //     break;
      //   case "Language":
      //     this.videoAreaTit = "族語誌";
      //     break;
      //   case "Plant":
      //     this.videoAreaTit = "植物誌";
      //     break;
      //   case "Tribe":
      //     this.videoAreaTit = "部落誌";
      //     break;
      //   default:
      //     console.log("如果都不符合，回首頁");
      //     this.$router.push("/");
      // }
      //== 讀取影片列表 ==
      this.getApiSearch();
    },
    //=== 讀取影片列表 ===
    getApiSearch() {
      apiSearch({ search: this.srcTxt })
        .then((result) => {
          console.log(result.data);
          if (result.data.data.length == 0) {
            // console.log(result.data.data);
            // alert("此資料不存在或已刪除。");
            // this.$router.push("/");
            this.isNodata = true;
          } else {
            console.log(result.data.data);
            this.list = result.data.data;
            this.isNodata = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 關閉影片 ===
    closeVideo() {
      this.videoLightboxShow = false;
      this.videoLightboxLink = "";
      this.videoLightboxTit = "";
      this.videoLightboxTxt = "";
    },
    //=== 回上一頁 ===
    backPage() {
      this.$router.go(-1);
    },
  },
  beforeDestroy() {
    //==== 元件銷毀前要取消監聽 ====
    this.$bus.$off("sendVideo");
  },
  watch: {
    $route() {
      // var nowType = this.$route.params.id;
      var nowSrcTxt = this.$route.query.src;
      console.log("== 更換項目 ==", nowSrcTxt);
      this.typeLoad(nowSrcTxt);
    },
  },
  components: {
    NavArea,
    Loading,
    Logo,
    VideoItem,
    PageFooter,
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>